
$bg-opacity: 0.5;
$bg-blur-radius: 8px;

.spin > svg {
  animation-name: rotate360;
  animation-duration: 0.8s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dynamic-background {
  position: fixed;
  height: inherit;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center center;
  opacity: $bg-opacity;
  filter: blur(#{$bg-blur-radius});

  transition:
    opacity ease-in-out 0.5s;
  z-index: 2!important;

  &.hidden {
    opacity: 0;
  }
}
