@import "../App.scss";

.recent-events {
  display: grid;
  grid-template-columns: 200px 10px 700px 200px;
  grid-template-rows: 1fr;
  grid-template-areas:
    "sidebar . content .";
  overflow-x: hidden;
  overflow-y: hidden;

  .recent-events-content {
    grid-area: content;
    height: 100%;
  }
  .recent-events-sidebar {
    grid-area: sidebar;
    overflow-y: auto;
    max-height: calc(100vh + 30px);
  }
}
@media (max-width: 991.98px) {
  .recent-events {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 220px 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      "sidebar content";
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

@media (max-width: 767.98px) {
  .recent-events {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "content"
      "sidebar";
    overflow-x: hidden;
    overflow-y: hidden;
    .recent-events-sidebar {
      margin-top: 2em;
      overflow-y: auto;
      max-height: 70vh;
    }
  }
}
