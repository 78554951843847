@import "../App.scss";

.header {
  height: $header-height;

  .header-content {
    position: relative;
    z-index: 15;
    margin-top: 3em;
    height: 100%;
  }
}
