
.datapanel-grid {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 200px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    "sidebar content";
  overflow-x: hidden;
  overflow-y: hidden;

  .datapanel-content {
    grid-area: content;
    height: 100%;
  }

  .datapanel-sidebar {
    grid-area: sidebar;
    overflow-y: auto;
    max-height: 100vh;
  }
}

@media (max-width: 991.98px) {
  .datapanel-grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "content"
      "sidebar";
    overflow-x: hidden;
    overflow-y: hidden;
    .datapanel-sidebar {
      overflow-y: auto;
      max-height: 70vh;
    }
  }
}
