@import "../App.scss";

nav {
  @include title-font(400);
  z-index: 999;
  transition:
    background-color 0.4s ease-in-out,
    box-shadow 0.4s ease-in-out;
  &.hover:hover {
    box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
  }
}

.light {
  /*nav {
    background-image: linear-gradient(
      rgba($bg-color-light,0), $bg-color-light 80%, $bg-color-light
    );
    &.hover:hover {
      background-color: $bg-color-light;
    }
  }*/
  .uk-navbar-nav>li:not(.uk-active)>a {
    color: #7b7b7b;
  }
}
.dark {
  /*nav {
    background-image: linear-gradient(
      rgba($bg-color-dark,0), $bg-color-dark 80%, $bg-color-dark
    );
    &.hover:hover {
      background-color: $bg-color-dark;
    }
  }*/
}
