@import "uikit/src/scss/variables-theme.scss";
@import "uikit/src/scss/mixins-theme.scss";
@import "uikit/src/scss/uikit-theme.scss";
@import url('https://fonts.googleapis.com/css?family=Lato:300,400');

@mixin title-font($weight: 400) {
  font-family: 'Lato', sans-serif;
  font-weight: $weight;
}

$bg-color-transparent: rgba(0,0,0,0);
$bg-color-light: rgba(#eeeeee, 0.8);
$bg-color-dark: rgba(#5a5a5a, 0.85);
$bg-color-darker: rgba(#2d2d2d, 0.85);

$text-color-light: black;
$text-color-dark: rgba(white, 1);

$header-height: 18em;


html {
    overflow-y: scroll;
    overflow-x: hidden;
}

hr {
  transition:
    border-color ease-in-out 0.5s;
}

.spa-content {
  transition:
    color ease-in-out 0.5s,
    background-color ease-in-out 0.5s;
  main {
    @include title-font(300);
    min-height: calc(100vh - 18em);
    position: relative;
    z-index: 10;
  }
  &.light {
    color: $text-color-light!important;
    background-color: $bg-color-light!important;
  }
  &.dark {
    color: $text-color-dark!important;
    background-color: $bg-color-dark!important;
    .card, .jumbotron {
      background-color: $bg-color-darker!important;
    }
    hr {
      border-color: $text-color-dark!important;
    }
  }
}

.card, .jumbotron {
  //apply same border as .card to match visual style
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.rounded-card {
  //slightly less radius than jumbotron
  border-radius: 0.20rem !important;
}
